import React, { FC, useState, PropsWithChildren } from 'react';
import { Producer } from '../api/types/producer';

export interface FakeAuth {
  producer?: Producer;
  loginAs: (producer: Producer) => void;
  logout: () => void;
}

export const FakeAuthContext = React.createContext<FakeAuth | null>(null);

export const FakeAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [producer, setProducer] = useState<Producer | undefined>(undefined);

  const loginAs = (producer: Producer) => {
    setProducer(producer);
  };

  const logout = () => {
    setProducer(undefined);
  };

  return <FakeAuthContext.Provider value={{ producer, loginAs, logout }}>{children}</FakeAuthContext.Provider>;
};
