import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/lib';

const FormInput: FC<FormItemProps> = props => {
  return (
    <Form.Item {...props}>
      <Input />
    </Form.Item>
  );
};

export default FormInput;
