import { AxiosResponse } from 'axios';
import Api from './Api';
import { Product, NewProduct } from './types/product';

export default class ProductApi {
  static async getAllByProducer(id: string): Promise<AxiosResponse<Product[]>> {
    return Api.get(`/product/producer/${id}`);
  }

  static async getOne(id: string): Promise<AxiosResponse<Product>> {
    return Api.get(`/product/${id}`);
  }

  static edit(id: string, product: NewProduct): Promise<AxiosResponse<Product>> {
    return Api.patch(`/product/${id}`, product);
  }
}
