import React, { FC, useState, useEffect } from 'react';
import { notification } from 'antd';
import { Producer } from '../../../api/types/producer';
import ProducerModal from '../../ui/producer/ProducerModal';
import ProducerApi from '../../../api/ProducerApi';

interface ProducerEditorProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  producerId: string;
  onEdit?: (newProduct: Producer) => void;
}

export const ProducerEditor: FC<ProducerEditorProps> = ({ visible, setVisible, producerId, onEdit }) => {
  const [producer, setProducer] = useState<Producer | undefined>();

  const onSubmit = async (values: Producer): Promise<void> => {
    try {
      await ProducerApi.edit(producer?._id ?? '', { ...producer, ...values });
      onEdit?.({ ...producer, ...values });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: 'Wystąpił błąd w trakcie zapisywania producenta.',
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: producer } = await ProducerApi.getOne(producerId || '');
        setProducer(producer);
      } catch (err) {
        notification.error({
          message: 'Wystąpił błąd w trakcie pobierania danych.',
        });
      }
    };
    fetchData();
  }, []);

  const handleCancel = (): void => {
    setVisible(false);
  };

  return <ProducerModal open={visible} onSubmit={onSubmit} handleCancel={handleCancel} producer={producer} />;
};
