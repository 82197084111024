import React, { FC, useEffect, useState } from 'react';
import DetailsPageWrapper from '../components/ui/common/DetailsPageWrapper';
import { useParams } from 'react-router-dom';
import { Empty, Card, Space, Typography, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ProducerApi from '../api/ProducerApi';
import { Producer } from '../api/types/producer';
import './ProducerDetails.css';
import { ProducerEditor } from '../components/feat/producer/ProducerEditor';

export const ProducerDetails: FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditorVisible, setIsEditorVisible] = useState<boolean>(false);
  const [producer, setProducer] = useState<Producer | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: producer } = await ProducerApi.getOne(id || '');
        setProducer(producer);
        console.log(producer);
        setIsLoading(false);
      } catch (err) {
        notification.error({
          message: 'Wystąpił błąd w trakcie pobierania danych.',
        });
      }
    };
    fetchData();
  }, []);

  const editProducer = (): void => {
    setIsEditorVisible(true);
  };

  return (
    <DetailsPageWrapper headText='Szczegóły producenta' isLoading={isLoading}>
      {producer ? (
        <>
          <br />
          <ProducerEditor visible={isEditorVisible} setVisible={setIsEditorVisible} producerId={producer._id} onEdit={setProducer} />
          <Space align='center'>
            <Card
              className='card--producerDetails'
              hoverable
              cover={<img src={producer.imageUrl} />}
              actions={[<EditOutlined key='edit' onClick={editProducer} />]}
            >
              <Card.Meta title={producer.companyName} description={producer.description} />
              <br />
              <Space align='center'>
                <Typography.Paragraph>
                  <b>Adres </b>
                  <br />
                  <div>Ulica: {producer.address.address}</div>
                  <div>Miasto: {producer.address.city}</div>
                  <div>Powiat: {producer.address.county}</div>
                  <div>Województwo: {producer.address.province}</div>
                  <div>Region: {producer.address.region}</div>
                  <div>Kraj: {producer.address.country}</div>
                </Typography.Paragraph>
              </Space>
              <br />
              <Space align='center'>
                <Typography.Paragraph>
                  <b>Forma Sprzedaży</b>
                  <br />
                  {producer.deliveryTypes.map((type, index) => (
                    <div key={index}>{type}</div>
                  ))}
                </Typography.Paragraph>
              </Space>
              <br />
              <Space align='center'>
                <Typography.Paragraph>
                  <b>Strona internetowa </b>
                  <br />
                  {producer.pageUrl}
                </Typography.Paragraph>
              </Space>
              <br />
              <Space align='center'>
                <Typography.Paragraph>
                  <b>Kontakt</b>
                  <br />
                  <div>Telefon: {producer.contact.tel}</div>
                  <div>E-mail: {producer.contact.email}</div>
                </Typography.Paragraph>
              </Space>
              <br />
            </Card>
          </Space>
        </>
      ) : (
        <Empty />
      )}
    </DetailsPageWrapper>
  );
};
