import Api from './Api';
import { Producer } from './types/producer';
import { AxiosResponse } from 'axios';

export default class ProducerApi {
  static getOne(id: string): Promise<AxiosResponse<Producer>> {
    return Api.get(`/producer/${id}`);
  }

  static getAll(): Promise<AxiosResponse<Producer[]>> {
    return Api.get('/producer/all');
  }
  static edit(id: string, producer: Producer): Promise<AxiosResponse<Producer>> {
    //moim zdaniem nie powinno byc tak ze trzeba cale dto wysylac za kazdym razem, nawet jak zmieniamy tylko jedno pole.
    const producerEdited = { ...producer, products: producer.products.map(product => product._id) };

    return Api.patch(`/producer/${id}`, producerEdited);
  }
}
