import React, { FC, useState } from 'react';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ProductEditor } from './ProductEditor';
import { Product } from '../../../api/types/product';
import Actions from '../../ui/common/Actions';

interface PropTypes {
  product: Product;
  onEdit: (newProduct: Product) => void;
}

export const ProductTableActions: FC<PropTypes> = ({ product, onEdit }) => {
  const [isEditorVisible, setIsEditorVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const showProductDetails = () => {
    navigate(`/product/${product._id}`);
  };

  const openProductEditor = (): void => {
    setIsEditorVisible(true);
  };

  return (
    <Space size='middle'>
      <ProductEditor visible={isEditorVisible} setVisible={setIsEditorVisible} product={product} onEdit={onEdit} />

      <Actions
        onShowDets={showProductDetails}
        onEdit={openProductEditor}
        onDelete={() => {
          console.log('delete not implemented');
        }}
      />
    </Space>
  );
};
