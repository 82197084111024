import React, { FC, useState, useEffect } from 'react';
import { notification } from 'antd';
import ProductApi from '../../../api/ProductApi';
import CategoriesApi from '../../../api/CategoriesApi';
import { Category } from '../../../api/types/category';
import { NewProduct, Product } from '../../../api/types/product';
import ProductModal from '../../ui/product/ProductModal';

interface ProductEditorProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  product: Product;
  onEdit?: (newProduct: Product) => void;
}

export const ProductEditor: FC<ProductEditorProps> = ({ visible, setVisible, product, onEdit }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data: categories } = await CategoriesApi.getAll();
        setCategories(categories);
      } catch (err) {
        notification.error({
          message: 'Wystąpił błąd w trakcie pobierania kategorii.',
        });
      }
    };

    fetchCategories();
  }, []);

  const onSubmit = async (values: NewProduct): Promise<void> => {
    try {
      await ProductApi.edit(product._id, values);
      onEdit?.({ ...values, _id: product._id });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: 'Wystąpił błąd w trakcie zapisywania produktu.',
      });
    }
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  return <ProductModal open={visible} product={product} onSubmit={onSubmit} categories={categories} handleCancel={handleCancel} />;
};
