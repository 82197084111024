import React, { FC, useEffect, useState } from 'react';
import { AlignType } from 'rc-table/lib/interface';
import { Table, notification } from 'antd';
import { Producer } from '../api/types/producer';
import ProducerApi from '../api/ProducerApi';
import ProducerActions from '../components/feat/producer/ProducerActions';

const ProducerList: FC = () => {
  const [producers, setProducers] = useState<Producer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchProducers = async () => {
    // if (!producer) {
    //   return;
    // }
    try {
      const { data: producers } = await ProducerApi.getAll();
      setProducers(producers);
    } catch (err) {
      notification.error({
        message: 'Wystąpił błąd w trakcie pobierania producentów.',
      });
    }
  };
  useEffect(() => {
    Promise.all([fetchProducers()]).then(() => {
      setIsLoading(false);
    });
  }, []);
  const handleEdit = async (newProducer: Producer) => {
    fetchProducers();
  };
  const columns = [
    {
      title: 'Zdjęcie',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      align: 'center' as AlignType,
      render: (text: string) => <img src={text} alt='not_found' width='50' height='50' />,
    },
    {
      title: 'Nazwa',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center' as AlignType,
    },

    {
      title: 'Akcje',
      key: 'action',
      align: 'center' as AlignType,
      render: (text: string, record: Producer) => <ProducerActions producer={record} onEdit={handleEdit} />,
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={producers} size='small' pagination={false} loading={isLoading} rowKey='_id' />
    </div>
  );
};
export default ProducerList;
