import React, { FC } from 'react';
import { Producer } from '../../../api/types/producer';
import { Modal, Form, Input } from 'antd';
import FormInput from '../common/FormInput';

interface ProducerModalProps {
  producer?: Producer;
  onSubmit(values: Producer): Promise<void>;
  handleCancel(): void;
  open: boolean;
}

const ProducerModal: FC<ProducerModalProps> = ({ producer, onSubmit, handleCancel, open }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      title='Edycja producenta'
      open={open}
      okText='Zapisz'
      onCancel={handleCancel}
      cancelText='Anuluj'
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onSubmit(values);
          })
          .catch(() => null);
      }}
    >
      <Form form={form} {...{ span: 5 }} name='nest-messages' id='editProductForm'>
        <FormInput
          initialValue={producer?.companyName}
          name={['companyName']}
          label='Nazwa firmy'
          rules={[{ required: true, message: 'Nazwa jest wymagana.' }]}
        />

        <Form.Item
          initialValue={producer?.description}
          name={['description']}
          label='Opis'
          rules={[{ required: true, message: 'Opis jest wymagany.' }]}
        >
          <Input.TextArea rows={8} />
        </Form.Item>
        <FormInput
          initialValue={producer?.imageUrl}
          name={['imageUrl']}
          label='Image URL'
          rules={[{ required: true, message: 'zdjęcie jest wymagane.' }]}
        />

        <FormInput
          initialValue={producer?.pageUrl}
          name={['pageUrl']}
          label='Adres strony'
          rules={[{ required: true, message: 'adres strony jest wymagana.' }]}
        />

        <FormInput
          initialValue={producer?.address.address}
          name={['address', 'address']}
          label='Ulica i numer'
          rules={[{ required: true, message: 'Ulica i numer jest wymagana.' }]}
        />
        <FormInput
          initialValue={producer?.address.city}
          name={['address', 'city']}
          label='Miasto'
          rules={[{ required: true, message: 'Miasto jest wymagana.' }]}
        />

        <FormInput
          initialValue={producer?.address.country}
          name={['address', 'country']}
          label='Kraj'
          rules={[{ required: true, message: 'Kraj jest wymagana.' }]}
        />

        <FormInput
          initialValue={producer?.address.region}
          name={['address', 'region']}
          label='Województwo'
          rules={[{ required: true, message: 'Województwo jest wymagana.' }]}
        />

        <FormInput
          initialValue={producer?.address.province}
          name={['address', 'province']}
          label='Prowincja'
          rules={[{ required: true, message: 'Prowincja jest wymagana.' }]}
        />

        <FormInput
          initialValue={producer?.address.county}
          name={['address', 'county']}
          label='Powiat'
          rules={[{ required: true, message: 'Powiat jest wymagana.' }]}
        />

        <FormInput
          initialValue={producer?.contact.email}
          name={['contact', 'email']}
          label='Email'
          rules={[{ required: true, message: 'Email jest wymagana.' }]}
        />

        <FormInput
          initialValue={producer?.contact.tel}
          name={['contact', 'tel']}
          label='tel'
          rules={[{ required: true, message: 'Telefon jest wymagana.' }]}
        />
      </Form>
    </Modal>
  );
};

export default ProducerModal;
