import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';

interface Props {
  icon: JSX.Element;
  tooltip: string;
  func?: () => void;
}

const ActionButton: FC<Props> = ({ icon, func, tooltip }) => {
  return func ? (
    <Tooltip title={tooltip}>
      <Button type='text' shape='circle' icon={icon} onClick={func} />
    </Tooltip>
  ) : null;
};

export default ActionButton;
