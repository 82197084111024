import { Select, Button, Form, Card } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProducerApi from '../api/ProducerApi';

import { Producer } from '../api/types/producer';
import { FakeAuth, FakeAuthContext } from '../context/FakeAuth';

import './FakeLoginPage.css';

export const FakeLoginPage: FC = () => {
  const { producer, loginAs } = useContext(FakeAuthContext) as FakeAuth;
  const [allProducers, setAllProducers] = useState<Producer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  if (producer) {
    navigateHome();
  }

  useEffect(() => {
    let isMouted = true;
    setIsLoading(true);

    ProducerApi.getAll().then(res => {
      if (isMouted) {
        if (res.status === 200) {
          setAllProducers(res.data);
        }
      }
      setIsLoading(false);
    });

    return () => {
      isMouted = false;
    };
  }, [setAllProducers, loginAs]);

  const options = allProducers.map(({ _id, companyName }) => (
    <Select.Option value={_id} key={_id}>
      {`${companyName} - ${_id}`}
    </Select.Option>
  ));

  const onLoginFinish = ({ user_id }) => {
    const producer = allProducers.find(p => p._id == user_id);
    if (producer) {
      loginAs(producer);
      navigateHome();
    }
  };

  return (
    <Card className='fake_login_card' title='Zaloguj'>
      <Form name='control-ref' title='Fake login' onFinish={onLoginFinish}>
        <Form.Item
          name='user_id'
          label='Użytkownik'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder='Wybierz użytkownika' loading={isLoading} disabled={isLoading}>
            {options}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' disabled={isLoading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
