import React, { FC, useEffect, useState } from 'react';
import { Input } from 'antd';
import { formatPrice } from '../../../util/formatter';

interface PriceInputProps {
  value?: number;
  onChange?: (value: number | null) => void;
}

type Price = number | null;

const PriceInput: FC<PriceInputProps> = ({ value, onChange }) => {
  const [numberValue, setNumberValue] = useState<Price>(value || null);
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    formatText();
  }, []);

  const formatText = () => {
    if (!Number.isNaN(numberValue) && numberValue !== null) {
      const newTextValue = formatPrice(numberValue);
      setTextValue(newTextValue);
    }
  };

  const getNumberValue = (textValue: string): Price => {
    if (textValue === '') {
      return null;
    }
    const cleanedText = textValue.replace(/\s+/g, '').replace(',', '.');
    return Number(cleanedText);
  };

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTextValue(e.target.value);
    const newNumberValue = getNumberValue(e.target.value);
    setNumberValue(newNumberValue);
    onChange?.(newNumberValue);
  };

  const onBlur = (): void => {
    if (!Number.isNaN(numberValue) && numberValue !== null) {
      formatText();
      const newNumberValue = Number(numberValue?.toFixed(2));
      setNumberValue(newNumberValue);
      onChange?.(newNumberValue);
    }
  };

  return <Input addonAfter='zł' type='text' value={textValue} onChange={onValueChange} onBlur={onBlur} />;
};

export default PriceInput;
