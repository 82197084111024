import { Space } from 'antd';
import React, { FC, useState } from 'react';
import Actions from '../../ui/common/Actions';
import { useNavigate } from 'react-router-dom';
import { Producer } from '../../../api/types/producer';
import { ProducerEditor } from './ProducerEditor';

interface ProducerActionsProps {
  producer: Producer;
  onEdit: (producer: Producer) => void;
}

const ProducerActions: FC<ProducerActionsProps> = ({ producer, onEdit }) => {
  const [isEditorVisible, setIsEditorVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const showProducerDetails = () => {
    navigate(`/producer/${producer._id}`);
  };

  const openProducerEditor = (): void => {
    setIsEditorVisible(true);
  };

  return (
    <Space size='middle'>
      <ProducerEditor visible={isEditorVisible} setVisible={setIsEditorVisible} producerId={producer._id} onEdit={onEdit} />
      <Actions
        onShowDets={showProducerDetails}
        onEdit={openProducerEditor}
        onDelete={() => {
          console.log('delete not implemented');
        }}
      />
    </Space>
  );
};

export default ProducerActions;
