import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Empty, Skeleton, Card, Space, Typography, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ProductEditor } from '../components/feat/product/ProductEditor';
import ProductApi from '../api/ProductApi';
import CategoriesApi from '../api/CategoriesApi';
import { Product } from '../api/types/product';
import { Category } from '../api/types/category';
import './ProductDetails.css';
import DetailsPageWrapper from '../components/ui/common/DetailsPageWrapper';

export const ProductDetails: FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditorVisible, setIsEditorVisible] = useState<boolean>(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [category, setCategory] = useState<Category | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: product } = await ProductApi.getOne(id || '');
        const { data: categories } = await CategoriesApi.getAll();
        setProduct(product);
        setCategory(categories.find(category => category.categoryId == product.category) || null);
        setIsLoading(false);
      } catch (err) {
        notification.error({
          message: 'Wystąpił błąd w trakcie pobierania danych.',
        });
      }
    };
    fetchData();
  }, []);

  const editProduct = (): void => {
    setIsEditorVisible(true);
  };

  return (
    <DetailsPageWrapper headText='Szczegóły produktu' isLoading={isLoading}>
      {product ? (
        <>
          <br />
          <ProductEditor visible={isEditorVisible} setVisible={setIsEditorVisible} product={product} onEdit={setProduct} />
          <Space align='center'>
            <Card
              className='product-details-card'
              hoverable
              cover={<img src={product.imageUrl} />}
              actions={[<EditOutlined key='edit' onClick={editProduct} />]}
            >
              <Card.Meta title={product.name} description={product.description} />
              <br />
              <Space align='center'>
                <Typography.Paragraph>
                  <b>Kategoria: </b>
                  {category?.name}
                </Typography.Paragraph>
              </Space>
              <br />
              <Space align='center'>
                <Typography.Paragraph>
                  <b>Cena: </b>
                  {product.price} zł/{product.unit}
                </Typography.Paragraph>
              </Space>
            </Card>
          </Space>
        </>
      ) : (
        <Empty />
      )}
    </DetailsPageWrapper>
  );
};
