import axios from 'axios';

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export default Api;
