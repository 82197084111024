import { Button, Space, Tooltip } from 'antd';
import React, { FC } from 'react';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import ActionButton from './ActionButton';

interface ActionsProps {
  onEdit?(): void;
  onShowDets?(): void;
  onDelete?(): void;
}

const Actions: FC<ActionsProps> = ({ onDelete, onEdit, onShowDets }) => {
  return (
    <Space size='middle'>
      <ActionButton icon={<EyeOutlined />} tooltip='Szczegóły' func={onShowDets} />
      <ActionButton icon={<EditOutlined />} tooltip='Edytuj' func={onEdit} />
      <ActionButton icon={<DeleteOutlined />} tooltip='Usuń' func={onDelete} />
    </Space>
  );
};

export default Actions;
