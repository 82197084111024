import React, { FC, useEffect, useState, useContext } from 'react';
import { notification, Table, Flex } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import { ProductTableActions } from '../components/feat/product/ProductTableActions';
import ProductApi from '../api/ProductApi';
import CategoriesApi from '../api/CategoriesApi';
import { Product } from '../api/types/product';
import { Category } from '../api/types/category';
import { formatPrice } from '../util/formatter';
import { FakeAuth, FakeAuthContext } from '../context/FakeAuth';
import AddProduct from '../components/feat/product/AddProduct';

export const ProductList: FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { producer } = useContext<FakeAuth | null>(FakeAuthContext) || {};

  const columns = [
    {
      title: 'Zdjęcie',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      align: 'center' as AlignType,
      render: (text: string) => <img src={text} alt='not_found' width='50' height='50' />,
    },
    {
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as AlignType,
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
      align: 'right' as AlignType,
      render: (text: number, record: Product) => (
        <p>
          {formatPrice(Number(text))} zł/{record.unit}
        </p>
      ),
    },
    {
      title: 'Kategoria',
      dataIndex: 'category',
      key: 'category',
      align: 'center' as AlignType,
      render: (id: string) => <p>{categories.find(category => category.categoryId === id)?.name}</p>,
    },
    {
      title: 'Akcje',
      key: 'action',
      align: 'center' as AlignType,
      render: (text: string, record: Product) => <ProductTableActions product={record} onEdit={handleEdit} />,
    },
  ];

  const handleEdit = (newProduct: Product): void => {
    if (products) {
      const productIndex = products.findIndex(product => product._id === newProduct._id);
      const newProducts = [...products];
      newProducts.splice(productIndex, 1, newProduct);
      setProducts(newProducts);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      if (!producer) {
        return;
      }
      try {
        const { data: products } = await ProductApi.getAllByProducer(producer?._id);
        setProducts(products);
      } catch (err) {
        notification.error({
          message: 'Wystąpił błąd w trakcie pobierania produktów.',
        });
      }
    };

    const fetchCategories = async () => {
      try {
        const { data: categories } = await CategoriesApi.getAll();
        setCategories(categories);
      } catch (err) {
        notification.error({
          message: 'Wystąpił błąd w trakcie pobierania kategorii.',
        });
      }
    };

    Promise.all([fetchProducts(), fetchCategories()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <h1>Jarmark</h1>
      <Flex>
        <AddProduct />
      </Flex>
      <Table columns={columns} dataSource={products} size='small' pagination={false} loading={isLoading} rowKey='_id' />
    </>
  );
};
