import React, { FC } from 'react';
import { ProductDetails } from './views/ProductDetails';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import { FakeLoginPage } from './views/FakeLoginPage';
import ProducerList from './views/ProducersList';
import { ProductList } from './views/ProductList';
import { ProducerDetails } from './views/ProducerDetails';
const App: FC = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<ProductList />} />
          <Route path='/product/:id' element={<ProductDetails />} />
          <Route path='login' element={<FakeLoginPage />} />
          <Route path='/producers' element={<ProducerList />} />
          {/* <Route path='*' element={<Navigate to='/login' />} /> */}
          <Route path='/producer/:id' element={<ProducerDetails />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
