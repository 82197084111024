import React, { FC } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import PriceInput from '../common/PriceInput';
import { NewProduct } from '../../../api/types/product';
import { Category } from '../../../api/types/category';
import FormInput from '../common/FormInput';

interface ProductModalProps {
  product?: NewProduct;
  onSubmit(values: NewProduct): Promise<void>;
  categories: Category[];
  handleCancel(): void;
  open: boolean;
}

const ProductModal: FC<ProductModalProps> = ({ product, onSubmit, handleCancel, open, categories }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      title='Edycja produktu'
      open={open}
      okText='Zapisz'
      onCancel={handleCancel}
      cancelText='Anuluj'
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onSubmit(values);
          })
          .catch(() => null);
      }}
    >
      <Form form={form} {...{ span: 5 }} name='nest-messages' id='editProductForm'>
        <FormInput
          initialValue={product?.name}
          name={['name']}
          label='Nazwa'
          rules={[{ required: true, message: 'Nazwa jest wymagana.' }]}
        />
        <Form.Item
          initialValue={product?.description}
          name={['description']}
          label='Opis'
          rules={[{ required: true, message: 'Opis jest wymagany.' }]}
        >
          <Input.TextArea rows={8} />
        </Form.Item>
        <FormInput
          initialValue={product?.imageUrl}
          name={['imageUrl']}
          label='URL obrazu'
          rules={[{ required: true, message: 'URL obrazu jest wymagane.' }]}
        />
        <Form.Item
          initialValue={product?.category}
          name={['category']}
          label='Kategoria'
          rules={[{ required: true, message: 'Kategoria jest wymagana.' }]}
        >
          <Select
            showSearch
            // filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            loading={categories.length === 0}
          >
            {categories.map((category, index) => (
              <Select.Option key={index} value={category.categoryId}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={product?.price}
          name={['price']}
          label='Cena'
          validateFirst
          rules={[
            { required: true, message: 'Cena jest wymagana.' },
            {
              type: 'number',
              message: 'Cena musi być liczbą',
            },
            { type: 'number', min: 0, message: 'Cena nie może być mniejsza od 0.' },
            { pattern: /^\d*[.,]?\d{1,2}$/, message: 'Cena może mieć maksymalnie dwa miejsca po przecinku.' },
          ]}
        >
          <PriceInput />
        </Form.Item>
        <Form.Item
          initialValue={product?.unit}
          name={['unit']}
          label='Jednostka'
          rules={[{ required: true, message: 'Jednostka jest wymagana.' }, {}]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductModal;
