import { PageHeader } from '@ant-design/pro-layout';
import { Skeleton } from 'antd';
import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface props {
  isLoading: boolean;
  headText: string;
}
const DetailsPageWrapper: FC<PropsWithChildren<props>> = ({ headText, children, isLoading }) => {
  const navigate = useNavigate();
  const navigateBack = (): void => {
    navigate(-1);
  };
  return (
    <>
      <PageHeader className='site-page-header' onBack={navigateBack} title={headText} />
      {!isLoading ? children : <Skeleton active />}
    </>
  );
};

export default DetailsPageWrapper;
