import { Button } from 'antd';
import React from 'react';

const AddProduct = () => {
  return (
    <>
      <Button type='primary'>Primary Button</Button>
    </>
  );
};

export default AddProduct;
